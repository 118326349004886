<!-- 最适合的高校大类专业内小类专业排序报告 -->
<template>
  <div class="main">
    <div class="main-title">
      <span
        style="left: 0; padding-left: 2rem; position: absolute; color: #2EA9DF;"
        >VIP版</span
      ><span>最适合的高校大类专业内小类专业排序报告</span>
      <div class="print-btn" @click="print()">
        <div class="print-btn-text-wrapper">
          <span>打印</span><span>报告</span>
        </div>
      </div>
    </div>
    <div class="main-subtitle">
      以下为您的小专业类匹配结果，按匹配度排名
    </div>
    <div class="main-parts-wrapper">
      <div
        class="main-part"
        v-for="(major, index) in majorsData"
        :key="major.code"
      >
        <div class="part-title">{{ index + 1 }}. {{ major.name }}</div>
        <div class="part-content">
          <div
            class="submajor-wrapper"
            v-for="(submajor, index) in major.submajors"
            :key="submajor.code"
          >
            <div class="submajor">
              <div class="submajor-number">{{ index + 1 }}</div>
              <div class="submajor-title">{{ submajor.majorname }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-buttons-wrapper">
      <!-- <a
        class="lj-btn lj-btn-third lj-btn-block main-button"
        @click="jumpPage('HomeMajorStdResultPage6')"
        >上一页</a
      > -->
      <a
        class="lj-btn lj-btn-primary lj-btn-block main-button"
        @click="jumpPage('reportCenter')"
        >返回目录</a
      >
    </div>
  </div>
</template>

<script>
import './../../../../Public/utils/chartjs/Chart'
import './../../../../Public/utils/highcharts/highcharts'
import { queryMatchingMajors } from './../../../../api/major'
export default {
  data: function () {
    return {
      majorsData: [
        {
          name: '教育学类',
          submajors: [{ majorname: '科学教育' }, { majorname: '素质教育' }]
        },
        {
          name: '教育学类',
          submajors: [{ majorname: '科学教育' }, { majorname: '素质教育' }]
        },
        {
          name: '教育学类',
          submajors: [{ majorname: '科学教育' }, { majorname: '素质教育' }]
        }
      ]
    }
  },
  computed: {
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    }
  },
  mounted: function () {
    if (this.isMobile !== null) {
      this.$router.push({
        path: 'HomeMajorStdResultPage5Mobile'
      })
      return
    }
    queryMatchingMajors({}).then(res => {
      if (res.rescode == 200) {
        console.log('queryMatchingMajors', res)
        this.majorsData = res.majorsData
        this.scrollToTop()
      }
    })
  },
  methods: {
    jumpPage: function (dest) {
      if (this.$route.query.page === 'reportcenter') {
        this.$router.push({ path: 'reportCenter' })
        return
      }
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({ path: dest })
    },
    scrollToTop: function () {
      if (!document.body.scrollTop || !document.documentElement.scrollTop)
        if (document.documentElement.scrollTop)
          var l = setInterval(function () {
            ;(document.documentElement.scrollTop =
              0.6 * document.documentElement.scrollTop),
              document.documentElement.scrollTop < 1 &&
                (clearInterval(l), (document.documentElement.scrollTop = 0))
          }, 30)
        else if (document.body.scrollTop)
          var n = setInterval(function () {
            ;(document.body.scrollTop = 0.6 * document.body.scrollTop),
              document.body.scrollTop < 1 &&
                (clearInterval(n), (document.body.scrollTop = 0))
          }, 30)
    },
    print: function () {
      this.$router.push({
        path: 'HomeMajorStdPrint'
      })
    }
  }
}
</script>

<style lang="less">
@import url('./../../../../Public/resources/css/templates/home/major/std_result6.min.css');

.main-parts-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.main-title {
  padding: 0;
}
</style>
